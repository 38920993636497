import React from 'react'
import Layout from '../components/layout'

const Contact = () => (
  <Layout>
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ textAlign: 'center' }}>Contact</h1>
      <p>Cash me ousside, howbow dah?</p>
      <p>Email me,<br />hit me up at 916.913.9473,<br />or connect with me on social media.</p>
      <p>Will update my contact soon!</p>
    </div>
  </Layout>
)

export default Contact
